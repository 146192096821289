
import 'reflect-metadata';
import { Options, Vue, Prop } from 'vue-property-decorator';


@Options({})
export default class Modal extends Vue {
  @Prop({ required: true }) isOpen!: boolean;
  @Prop({ default: false }) noCloseIcon!: boolean;
}
