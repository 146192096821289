
import 'reflect-metadata';
import { nextTick } from 'vue';
import {
  Options, Vue, Prop, Ref, Watch
} from 'vue-property-decorator';
import { getAudioContext } from '@jcc/tools/utils';
import CONFIG from '@jcc/config';


const filter = (() => {
  let isInitialised = false;
  let lastOutput!: number;
  const alfa = 1 / CONFIG.VOLUME_FILTER_MODIFIER;
  return (input: number): number => {
    if (isInitialised) {
      lastOutput = alfa * input + (1 - alfa) * lastOutput;
    } else {
      isInitialised = true;
      lastOutput = input;
    }
    return lastOutput;
  };
})();

@Options({})
export default class VolumeTracker extends Vue {
  @Prop({ required: true }) source!: MediaStream;
  @Ref() indicator!: HTMLDivElement;
  isRedrawing = true;

  @Watch('source')
  async onSourceChange(): Promise<void> {
    this.isRedrawing = false;
    await nextTick();
    this.isRedrawing = true;
    this.trackVolumeLevel();
  }

  mounted(): void {
    this.trackVolumeLevel();
  }

  trackVolumeLevel(): void {
    const context = getAudioContext();
    const audioSource = context.createMediaStreamSource(this.source);
    const analyser = context.createAnalyser();
    audioSource.connect(analyser);
    analyser.fftSize = 256;
    const bufferLength = analyser.frequencyBinCount;
    const NORM = 128;
    let max!: number;
    const draw = (): void => {
      if (this.isRedrawing) {
        requestAnimationFrame(draw);
      }
      const bwDataArr = new Uint8Array(bufferLength);
      analyser.getByteTimeDomainData(bwDataArr);
      max = 0;
      for (let i = 0; i < bufferLength; i += 1) {
        max = Math.max(max, Math.abs(bwDataArr[i]));
      }
      const maxLvl = +(this.indicator.getAttribute('maxLevel') as string);
      let filteredLvl = filter((max - NORM) / (maxLvl - NORM));
      if (filteredLvl > 1) {
        filteredLvl = 1;
      }
      /* Минимальная заполнненость шкалы - 72px, максимальная - 188px */
      this.indicator.style.width = `${72 + filteredLvl * (188 - 72)}px`;
      if (max > maxLvl && max < 190) {
        this.indicator.setAttribute('maxLevel', `${max}`);
      }
    };
    draw();
  }

  beforeUnmount(): void {
    this.isRedrawing = false;
  }
}
