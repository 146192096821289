import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "VolumeTracker" }
const _hoisted_2 = { class: "VolumeTracker__background" }
const _hoisted_3 = {
  class: "VolumeTracker__indicator",
  ref: "indicator",
  name: "soundIndicator",
  maxLevel: "140"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, null, 512 /* NEED_PATCH */)
    ])
  ]))
}