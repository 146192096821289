import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "Player"
}
const _hoisted_2 = ["srcObject", "muted"]
const _hoisted_3 = ["muted", "srcObject"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Loader = _resolveComponent("Loader")!
  const _component_VolumeTracker = _resolveComponent("VolumeTracker")!

  return (!_ctx.source || _ctx.isNoTracksSource)
    ? (_openBlock(), _createBlock(_component_Loader, {
        key: 0,
        isNoConnection: !_ctx.isConnected
      }, null, 8 /* PROPS */, ["isNoConnection"]))
    : (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("video", {
          class: _normalizeClass(["Player__video", { 'Player__video--controlled': _ctx.withFullscreenOption }]),
          style: _normalizeStyle({      maxWidth: _ctx.maxSizes.width,      maxHeight: _ctx.maxSizes.height    }),
          name: "video",
          playsinline: "",
          disablepictureinpicture: "",
          ref: "videoEl",
          srcObject: _ctx.withSourcesSplit ? _ctx.videoSource : _ctx.source,
          muted: _ctx.isMuted,
          onLoadedmetadata: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.launchVideo && _ctx.launchVideo(...args)))
        }, null, 46 /* CLASS, STYLE, PROPS, HYDRATE_EVENTS */, _hoisted_2),
        (_ctx.withSourcesSplit && _ctx.withAudio)
          ? (_openBlock(), _createElementBlock("audio", {
              key: 0,
              ref: "audioEl",
              autoplay: "",
              muted: _ctx.isMuted,
              srcObject: _ctx.audioSource
            }, null, 8 /* PROPS */, _hoisted_3))
          : _createCommentVNode("v-if", true),
        (_ctx.withVolumeTracking && _ctx.withAudio)
          ? _withDirectives((_openBlock(), _createBlock(_component_VolumeTracker, {
              key: 1,
              class: "Player__volumeTracker",
              source: _ctx.withSourcesSplit ? _ctx.audioSource : _ctx.source
            }, null, 8 /* PROPS */, ["source"])), [
              [_vShow, _ctx.showVolumeTracking]
            ])
          : _createCommentVNode("v-if", true)
      ]))
}