
import 'reflect-metadata';
import {
  Options, Vue, Prop, Ref, Watch
} from 'vue-property-decorator';
import type { Sizes } from '@jcc/tools/interfaces';
import Loader from './Loader.vue';
import VolumeTracker from './VolumeTracker.vue';

@Options({
  components: {
    Loader,
    VolumeTracker,
  },
})
export default class Player extends Vue {
  @Prop({ required: true }) source!: MediaStream | null;
  @Prop({ default: true }) isConnected!: boolean;
  @Prop({ default: false }) isMuted!: boolean;
  @Prop({ default: '' }) status!: string;
  @Prop({ default: false }) fullscreenSwitch!: boolean;
  @Prop({ default: false }) withFullscreenOption!: boolean;
  @Prop({ default: { width: '100%', height: '100%' } }) maxSizes!: Sizes;
  @Prop({ default: false }) withSourcesSplit!: boolean;
  @Prop({ default: false }) withVolumeTracking!: boolean;
  @Prop({ default: true }) showVolumeTracking!: boolean;
  @Ref() videoEl!: HTMLVideoElement;
  @Ref() audioEl!: HTMLAudioElement;
  videoSource: MediaStream | null = null;
  audioSource: MediaStream | null = null;

  get withAudio(): boolean {
    const audioSource: MediaStream | null = this.withSourcesSplit ? this.audioSource : this.source;
    return !!(audioSource?.getAudioTracks() ?? []).length;
  }

  @Watch('fullscreenSwitch')
  onFullscreenSwitchChange(newVal: boolean, oldVal: boolean): void {
    if (newVal !== oldVal) {
      this.openFullscreen();
    }
  }

  @Watch('source')
  onSourceChange(newSource: MediaStream | null): void {
    if (newSource) {
      if (!this.withSourcesSplit) return;
      const videoTracks = newSource.getVideoTracks() ?? [];
      if (!this.videoSource || videoTracks[0].id !== this.videoSource.getVideoTracks()[0].id) {
        const newVideoStream = new MediaStream();
        for (const track of videoTracks) {
          newVideoStream.addTrack(track);
        }
        this.videoSource = newVideoStream;
      }
      const audioTracks = newSource.getAudioTracks() ?? [];
      if (!this.audioSource || audioTracks[0].id !== this.audioSource.getAudioTracks()[0].id) {
        const newAudioStream = new MediaStream();
        for (const track of audioTracks) {
          newAudioStream.addTrack(track);
        }
        this.audioSource = newAudioStream;
      }
    } else {
      this.videoSource = null;
      this.audioSource = null;
      this.videoEl.srcObject = null;
      this.audioEl.srcObject = null;
    }
  }

  get isNoTracksSource(): boolean {
    return !this.source?.getVideoTracks()?.length && !this.source?.getAudioTracks()?.length;
  }

  launchVideo(e: Event): void {
    (e.target as HTMLVideoElement).play();
  }

  openFullscreen(): void {
    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    const videoEl = this.videoEl as any;
    const methods = ['requestFullscreen', 'webkitRequestFullscreen', 'webkitEnterFullscreen'];
    const usedMethod = methods.find((method) => !!videoEl[method]);
    videoEl[usedMethod as keyof typeof videoEl]();
  }
}
